import { ref, unref, reactive } from "@vue/composition-api";
import cloneDeep from "lodash/cloneDeep";
import UnitTypedUser from "@/modules/Project/classes/UnitTypedUser";

export default function useAssignToUnit(roleType, { props, context }) {
    let typedUser = new UnitTypedUser(unref(props.type), roleType);

    // Data
    const isToAssignLoading = ref(false);
    const toAssign = ref([]);
    const toAssignPagination = reactive({
        page: 1,
        total: 1
    });

    async function loadToAssign(search = "") {
        const perPage = 15;

        let params = {
            queries: {
                "name[partial]": search
            },
            page: toAssignPagination.page,
            perPage: perPage
        };

        try {
            isToAssignLoading.value = true;

            let res = await typedUser.getUsers()(
                unref(props.projectId),
                params
            );
            setToAssignData(res);

            isToAssignLoading.value = false;
        } catch (error) {
            isToAssignLoading.value = false;
            context.root.$notify({
                group: "alert",
                type: "error",
                title: "Error",
                text: `Failed to fetch available ${typedUser.label}. Please try again later.`
            });
        }
    }

    async function loadNextToAssign(scrollState) {
        let page = toAssignPagination.page;
        let total = toAssignPagination.total;
        // Load more if there are more pages
        if (page < total) {
            toAssignPagination.page++;
            await loadToAssign();
            scrollState.loaded();
        } else {
            scrollState.loaded();
            scrollState.complete();
        }
    }

    function setToAssignData(res) {
        try {
            // Append data to current user list
            toAssign.value = [...toAssign.value, ...cloneDeep(res.data)];

            // Update total page
            toAssignPagination.total = res.meta.pagination.total_pages;
        } catch (error) {
            console.error(error);
            throw error;
        }
    }

    function resetToAssignState() {
        toAssign.value = [];
        Object.assign(
            toAssignPagination,
            reactive({
                page: 1,
                total: 1
            })
        );
    }

    function searchToAssign(search) {
        resetToAssignState();
        loadToAssign(search);
    }

    return {
        isToAssignLoading,
        toAssign,
        toAssignPagination,
        loadToAssign,
        loadNextToAssign,
        searchToAssign,
        resetToAssignState
    };
}
