import ProjectUnitAPI from "@/modules/Project/api/projectUnit";
import ProjectAPI from "@/modules/Project/api/project";
import TypedUser from "./TypedUser";

export default class UnitTypedUser extends TypedUser {
    projectUnitAPI;

    constructor(apiType, roleType) {
        super(roleType);
        this.projectUnitAPI = new ProjectUnitAPI(apiType);
        this.projectAPI = new ProjectAPI(apiType);
    }

    getUsers() {
        if (this.roleType === TypedUser.TYPE.BANKER) {
            return this.projectAPI.getAssignedBankers;
        } else if (this.roleType === TypedUser.TYPE.LAWYER) {
            return this.projectAPI.getAssignedLawyers;
        }
    }
    getUnassignedUsers() {
        if (this.roleType === TypedUser.TYPE.BANKER) {
            return this.projectUnitAPI.getUnassignedBankers;
        } else if (this.roleType === TypedUser.TYPE.LAWYER) {
            return this.projectUnitAPI.getUnassignedLawyers;
        }
    }
}
