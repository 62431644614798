<template>
  <div class="assign-unit-lawyer">
    <fd-form-section title="Assign Lawyers">
      <!-- To Assign List -->
      <LawyersToAssign
        class="col-12 md-col-6 px-3"
        :lawyers="lawyersAvailable"
        :isLoading="isLawyerToAssignLoading"
        @search="searchLawyerToAssign"
        @assign="assignLawyer"
        @infinite-scroll="loadNextLawyersToAssign"
      >
        <template #lawyer="{ lawyer }">
          <lawyer :lawyer="lawyer">
            <template #action>
              <div class="d-flex align-items-center">
                <fd-button
                  class="bordered main sm ml-1"
                  icon="fas fa-angle-right"
                  @click="assignSPA(lawyer)"
                  >SPA</fd-button
                >
                <fd-button
                  class="bordered main sm ml-1"
                  icon="fas fa-angle-right"
                  @click="assignLA(lawyer)"
                  >LA</fd-button
                >
                <fd-button
                  class="bordered main sm ml-1"
                  @click="assignBoth(lawyer)"
                  ><i class="fas fa-angles-right"></i
                ></fd-button>
              </div>
            </template>
          </lawyer>
        </template>
      </LawyersToAssign>

      <!-- Assigned List -->
      <div class="col-12 md-col-6 px-3">
        <div class="mb-4">Lawyers Allcoated</div>

        <UnitLawyersAssigned
          v-model="SPALawyer"
          :isLoading="isAssignedLawyersLoading"
          type="spa"
          class="mb-3"
          required
        ></UnitLawyersAssigned>
        <UnitLawyersAssigned
          v-model="LALawyer"
          :isLoading="isAssignedLawyersLoading"
          type="la"
          class="mb-3"
          required
        ></UnitLawyersAssigned>
      </div>
    </fd-form-section>
  </div>
</template>

<script>
import useAssignToUnit from "@/modules/Project/composables/useAssignToUnit";
import differenceBy from "lodash/differenceBy";

import ManagerRoles from "@/modules/Project/classes/ManagerRoles";
import ProjectUnitAPI from "@/modules/Project/api/projectUnit";

export default {
  setup(props, context) {
    const {
      toAssign: lawyersToAssign,
      isToAssignLoading: isLawyerToAssignLoading,
      searchToAssign: searchLawyerToAssign,
      loadToAssign: loadLawyersToAssign,
      loadNextToAssign: loadNextLawyersToAssign,
      resetToAssignState: resetToAssignLawyersState
    } = useAssignToUnit("lawyer", { props, context });

    return {
      // Assign To Unit
      lawyersToAssign,
      isLawyerToAssignLoading,
      searchLawyerToAssign,
      loadLawyersToAssign,
      loadNextLawyersToAssign,
      resetToAssignLawyersState
    };
  },
  components: {
    LawyersToAssign: () => import("./LawyersToAssign"),
    Lawyer: () => import("./Lawyer"),
    UnitLawyersAssigned: () => import("./UnitLawyerAssigned")
  },
  mixins: [],
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    projectId: {
      type: [Number, String]
    },
    projectUnitId: {
      type: [Number, String]
    },
    type: {
      type: String,
      validator: (val) => new ManagerRoles().getRoles().includes(val)
    },
    mapValue: {
      type: Function,
      default: (val) => val
    }
  },
  data: function () {
    return {
      projectUnitAPI: new ProjectUnitAPI(this.type),

      isAssignedLawyersLoading: false,
      SPALawyer: {},
      LALawyer: {}
    };
  },
  computed: {
    lawyersAvailable() {
      // TODO: Difference by SPA & LA Lawyer
      let lawyers = differenceBy(
        this.lawyersToAssign,
        this.lawyersAssigned,
        (b) => b.id
      );
      return lawyers;
    }
  },
  watch: {
    SPALawyer(val) {
      this.$emit("input", {
        spaLawyerId: this.mapValue(val),
        laLawyerId: this.mapValue(this.LALawyer)
      });
    },
    LALawyer(val) {
      this.$emit("input", {
        spaLawyerId: this.mapValue(this.SPALawyer),
        laLawyerId: this.mapValue(val)
      });
    }
  },
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await this.loadLawyersToAssign();
      if (this.isEdit) {
        this.loadLawyersAssigned();
      }
    },
    assignSPA(lawyer) {
      this.SPALawyer = lawyer;
    },
    assignLA(lawyer) {
      this.LALawyer = lawyer;
    },
    assignBoth(lawyer) {
      this.assignSPA(lawyer);
      this.assignLA(lawyer);
    },
    assignLawyer(lawyer) {
      // Assign to SPA or LA
      this.SPALawyer = lawyer;
      this.LALawyer = lawyer;
    },
    async loadLawyersAssigned() {
      try {
        this.isAssignedLawyersLoading = true;
        let data = await this.projectUnitAPI.getAssignedLawyers(
          this.projectUnitId
        );
        this.SPALawyer = data.salesPurchaseAgreementLawyer;
        this.LALawyer = data.loanAgreementLawyer;
        this.isAssignedLawyersLoading = false;
      } catch (error) {
        this.isAssignedLawyersLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch allocated lawyers. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
