export default class TypedUser {
    roleType;
    label;

    static TYPE = {
        BANKER: "banker",
        LAWYER: "lawyer"
    };

    constructor(roleType) {
        this.roleType = roleType;

        if (roleType === TypedUser.TYPE.BANKER) {
            this.label = "banker";
        } else if (roleType === TypedUser.TYPE.LAWYER) {
            this.label = "lawyer";
        }
    }
}
